html,body{
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
body{
  font-family: $font_family_sans;
  font-size: $font_size_regular;
  background-color: white;
  color: $black;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}
::selection{
  background-color: rgba($middle_grey, .2);
}
*{
  box-sizing: border-box;
}

a{
  color: $primary_color;
  text-decoration: none;
}

.inner{
  position: relative;
  max-width: 1200px;
  width: calc(100% - 40px);
  margin: auto;

  &--flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
