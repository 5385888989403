.page--app{
  .sub-menu{
    background-color: $blue;

    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;

      li{
        display: inline-block;
        vertical-align: middle;

        &:not(:first-child){
          margin-left: 30px;
        }
        a{
          display: block;
          padding: 10px 0;
          color: rgba(white, .5);
          font-size: 16px;
          border-bottom: 3px solid transparent;
          transition: color .1s ease-in-out;

          &:hover{
            color: rgba(white, .7);
          }

          &.active{
            color: white;
            border-bottom-color: white;
          }
        }
      }
    }
  }
  .page__inner{
    padding-top: 40px;
  }
  .page__title{
    font-size: $font_size_large;
  }
}
