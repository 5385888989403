h1,.h1,
h2,.h2,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6{
  line-height: 1.2em;
}
h1,.h1{

}

h4,.h4{
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
