.field--select{
  select{
    display: inline-block;
    appearance: none;
    font-family: $font_family_sans;
    font-size: 16px;
    border: 0;
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid darken(#f3f3f3, 2%);
    padding: 10px 0;
    border-radius: 0;
    cursor: pointer;
    outline: none !important;

    &:focus{
      border-bottom: 1px solid darken(#f3f3f3, 7%);
    }
    &:invalid{
      color: $dark_grey;
    }
  }
  &:hover:after{
    color: $dark_grey;
  }
  &:after{
    @extend .material-icons;
    content: 'keyboard_arrow_down';
    pointer-events: none;
    position: absolute;
    right: 0;
    top: calc(50% + 5px);
    transform: translateY(-50%);
    color: $middle_grey;
    font-size: 16px;
    transition: all $default_transition;
  }
}
