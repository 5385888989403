.single--property{
  .row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .col{
      flex-basis: 50%;
    }
  }

  .activity-list{
    margin-left: auto;
    max-width: 300px;
    border-left: 1px solid $light_grey;
    padding-left: 40px;

    @include breakSm{
      padding-left: 0;
      margin-top: 40px;
      max-width: none;
    }

    &__header{
      position: relative;

      h3{
        margin: 0;
        font-weight: 500;
        font-size: $font_size_large;
      }
      .button{
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        border-radius: 100%;
        font-size: 20px;

        .material-icons{
          font-size: 20px;
        }
      }
    }
    &__body{
      .activity-items{
        padding: 0;
        list-style-type: none;

        .activity-item{
          display: block;
          position: relative;
          padding: 5px 0 5px 20px;
          font-size: 16px;

          &:before{
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 14px;
            background-color: $middle_grey;
          }

          &--completed:before{
            background-color: $green;
          }

          &:not(:last-child){
            border-bottom: 1px solid $light_grey;
          }

          & + .activity-item{
            margin-top: 10px;
          }
        }
      }
    }
  }
}
