.page--placeholder{
  .page__inner{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @extend .inner;

    @include breakSm{
      padding-top: 30px;
    }
  }
  .page__icon{
    margin-bottom: 40px;

    img{
      width: 160px;
      height: 160px;
      object-fit: contain;
    }
  }
  .page__title{
    font-size: 40px;

    // TODO: create h-presets (responsive ones)

    @include breakSm{
      font-size: 30px;
    }
  }
  .page__content{
    max-width: 600px;
    margin-top: 40px;
    color: $middle_grey;

    .button{
      margin-top: 20px;
    }
  }
}
