.single{
  &__title{
    font-size: 32px;
    font-weight: 500;
    margin-top: $spacing_small;
    margin-bottom: $spacing_small;
  }
  &__actions{
    margin-bottom: $spacing_small;

    .button{
      & + .button{
        margin-left: $spacing_tiny;
      }
    }
  }
  &__subtitle{
    color: $middle_grey;
    margin-bottom: $spacing_small;

    a{
      color: $dark_grey;
    }
  }
  &__body{
    margin-top: $spacing_medium;

    .row{
      @include breakSm{
        flex-direction: column;
      }
    }
    .col{
      @include breakSm{

      };
    }
    .section{
      &__title{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: $spacing_small;
      }
    }
  }
}
