.button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  border-radius: $default_border_radius;
  font-family: 'sofia-pro';
  letter-spacing: .05em;

  font-weight: 500;
  padding: 13px 30px 15px;
  font-size: 16px;
  letter-spacing: .05em;
  outline: none !important;
  cursor: pointer;
  line-height: 21px;
  text-align: center;
  letter-spacing: $default_letter_spacing;
  text-decoration: none;
  transition: all .1s;

  &:hover{
    opacity: .9;
  }
  &[disabled]{
    opacity: .1;
    user-select: none;
    pointer-events: none;
  }

  &--primary{
    color: white;

    &.button--black{
      background-color: $black;

      &:hover{
        background-color: lighten($black, 5%);
      }
    }
    &.button--blue{
      background-color: $blue;

      &:hover{
        background-color: lighten($blue, 5%);
      }
    }
    &.button--green{
      background-color: $green;

      &:hover{
        background-color: darken($green, 5%)
      }
      &[disabled]{
        opacity: 1;
        color: rgba(white, .6);
      }
    }
    &.button--grey,
    &.button--gray{
      background-color: $light_grey;
      color: $middle_grey;

      &:hover{
        background-color: darken($light_grey, 5%)
      }
      &[disabled]{
        opacity: 1;
        color: rgba($middle_grey, .6);
      }
    }
  }
  &--naked{
    background: transparent;
    color: $dark_grey;

    &:hover{
      background-color: $light_grey;
    }
  }

  &--small{
    font-size: $font_size_small;
    padding: 5px 10px;
  }
  &.back{
    &:before{
      content: '\2190';
      margin-right: .3em;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
