.modal{
  background-color: rgba($black, .3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);

  &__box{
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 40px -20px rgba($black, .5);

    &__head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px;
      font-size: 16px;
      font-weight: 600;

      .close{
        display: flex;
        align-items: center;
        justify-content: center;
        background: $light_grey;
        appearance: none;
        border: 0;
        padding: 6px;
        cursor: pointer;
        border-radius: 50px;

        svg{
          width: 12px;
          height: 12px;

          path{
            stroke: $black;
          }
        }
      }
    }
    &__body{
      display: flex;
      flex-direction: column;
      padding: 40px;
      padding-top: 0;
      min-width: 500px;
    }
    &__footer{
      display: flex;
      padding: 20px 30px;
      background-color: $light_grey;
      justify-content: flex-end;
    }
  }
}


.modal--success{
  text-align: center;

  .modal__box{
    &__body{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 50px 60px;
      max-width: 300px;

      > .icon{
        margin-bottom: 30px;
      }
      > .title{
        font-size: $font_size_large;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
    &__footer{
      justify-content: center;
    }
  }
}
