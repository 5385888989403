@include breakSm{
  .header{
    &__menu{
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      background: white;
      margin: 0;
      height: 100vh;
      z-index: 100;
      width: 80%;
      padding: 20px;
      padding-top: 100px;

      opacity: 0;
      transform: translateX(-100%);
      pointer-events: none;
      transition: all .2s ease-in-out;

      &.visible{
        opacity: 1;
        transform: none;
        pointer-events: all;
      }

      li{
        display: block;
        margin: 0;
        font-size: 22px;

        a{
          display: block;
          padding: 10px 0;
        }
      }
    }
    &__cover{
      display: block;
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
      top: 0;
      background-color: rgba($dark_grey, .7);
      z-index: 60;

      opacity: 0;
      pointer-events: none;
      transition: opacity .2s ease-in-out;

      &.visible{
        opacity: 1;
        pointer-events: all;
      }
    }
  }
};
