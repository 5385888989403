@mixin breakXs {
  @media screen and (max-width: $break-xs) {
    @content;
  }
}
@mixin breakSm {
  @media screen and (max-width: $break-sm) {
    @content;
  }
}
@mixin breakMd {
  @media screen and (max-width: $break-md) {
    @content;
  }
}
@mixin breakLg {
  @media screen and (max-width: $break-lg) {
    @content;
  }
}
