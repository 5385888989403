.page--onboarding{
  min-height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include breakSm{
    padding: 20px;
  }

  .box{
    width: 100%;
    max-width: 800px;
    background-color: white;
    padding: 40px 80px;
    margin: auto;
    text-align: center;
    border-radius: $default_border_radius;

    @include breakSm{
      width: 100%;
      height: auto;
      max-width: none;
      padding: 40px 20px 20px;
      margin-top: auto;
    };

    p{
      color: $middle_grey;
      max-width: 80%;
      margin: auto;

      @include breakSm{
        max-width: none;
      }
    }
    .icon{
      width: 160px;
      height: auto;
    }
    .inner{
      @include breakSm{
        width: 100%;
      }
    }
    .button{
      @include breakSm{
        width: 90%;
      }
    }
  }
  .logo{
    margin-top: 60px;
    height: 26px;
    flex-shrink: 0;
    width: auto;

    path{
      fill: white;
    }
    ellipse{
      fill: white;
    }
  }
}
