.field{
  position: relative;
  padding-top: 10px;

  & + .field{
    margin-top: 10px;
  }
  label{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: $dark_grey;
    transition: all .2s $default_transition_ease;
  }
  &--focused,
  &--dirty{
    label{
      color: $primary_color;
      transform: translateY(-5px);
      top: 0;
      font-size: 14px;

      &:after{
        opacity: 0;
      }
    }
  }
  &--required{
    label{
      &:after{
        content: '*';
        margin-left: .1em;
        color: $red;
      }
    }
  }
}

textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"]{
  display: inline-block;

  appearance: none;
  font-family: $font_family_sans;
  font-size: 16px;
  border: 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid darken(#f3f3f3, 2%);
  padding: 10px 0;
  border-radius: 0;
  cursor: text;
  outline: none !important;

  &:focus{
    border-bottom: 1px solid darken(#f3f3f3, 7%);
  }
  &:disabled{
    color: inherit;
    cursor: default;
  }
  &::placeholder{
    color: rgba(black, .2);
  }
}
