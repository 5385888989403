.cookie-notice{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px 30px;
  z-index: 999;
  background-color: white;
  color: $middle_grey;
  box-shadow: $default_box_shadow;
  border-radius: $default_border_radius;
  max-width: 800px;
  text-align: center;
  transition: all .3s $default_transition_ease;

  @include breakSm{
    padding: 30px;
    bottom: 0;
  };

  &--dismissed{
    transform: translate(-50%, 100%);
    opacity: 0;
    pointer-events: none;
  }
  .actions{
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .button{
      appearance: none;
      -webkit-appearance: none;
      display: inline-block;
      vertical-align: middle;
      border: 0;
      outline: none;
      background-color: white;
      border: 1px solid transparent;
      color: black;
      padding: 10px 30px;
      text-transform: uppercase;
      font-size: 16px;

      & + .button{
        margin-left: 10px;
      }
      &--primary{
        background-color: $green;
        color: white;
      }
      &--secondary{
        background-color: $light_grey;
        color: $middle_grey;
      }
    }
  }
}
