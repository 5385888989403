.tag{
  display: inline-flex;
  font-size: $font_size_small;
  padding: 5px;
  line-height: 1em;
  border-radius: $default_border_radius;
  vertical-align: middle;

  &--grey{
    background-color: $middle_grey;
    color: white;
  }
  &--yellow{
    background-color: $yellow;
    color: white;
  }
  &--blue{
    background-color: $blue;
    color: white;
  }
  &--green{
    background-color: $green;
    color: white;
  }
}
