.material-icons{
	font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
	font-feature-settings: 'liga';
	ms-font-feature-settings:'liga'
}

.error-message{
	background-color: $red;
	color: white;
	font-size: $font_size_small;
	padding: 6px 10px;
	border-radius: $default_border_radius;
	line-height: 1.3em;
	margin-bottom: 10px;
}

.message-box{
	display: block;
	padding: 6px 10px;
	border-radius: $default_border_radius;
	line-height: 1.3em;
	font-size: $font_size_small;
}
.error-message{
	@extend .message-box;
	background-color: $red;
	color: white;
	margin-bottom: 10px;
}

.info-message{
	@extend .message-box;
	background-color: $light_grey;
	color: $middle_grey;
	margin-bottom: 10px;
}

small, p.small{
	font-size: $font_size_small;
	line-height: 1.5em;
}
