table{
  width: 100%;
  text-align: left;

  tr{
    border-collapse: collapse;

    &:hover .actions{
      opacity: 1;
    }
    td{
      padding: 8px 0;
      border-bottom: 1px solid $light_grey;

      .actions{
        background-color: $light_grey !important;
        width: 36px;
        height: 36px;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0;

        span{
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background: $dark_grey;
        }
      }
    }
  }
}
