.create-form{
  max-width: 600px;

  input{
    & + input{
      margin-top: 10px;
    }
  }

  &__actions{
    display: block;
    margin-top: 20px;

    .button{
      margin-top: 10px;

      @include breakSm{
        width: 100%;
      }
    }
  }
}
