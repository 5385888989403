.single--user{
  .row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .col{
      flex: 1;
    }
  }
}
