.page--authentication{
  min-height: 100vh;

  @include breakSm{
    display: flex;
    flex-direction: column;
  }

  .box{
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 60px;
    height: 100vh;

    @include breakMd{
      width: 70%;
      max-width: none;
    }
    @include breakSm{
      width: 100%;
      height: auto;
      max-width: none;
      padding: 50px 30px;
      margin-top: auto;
    };

    .inner{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
    }

    p{
      color: $middle_grey;
      font-size: $font_size_small;
      line-height: 1.667em;
    }
    form{
      margin-bottom: 10px;

      .hint{
        font-size: 12px;
        text-align: right;
        margin-top: 10px;
        margin-bottom: 20px;
        display: inline-block;
        color: $middle_grey;

        &:after{
          content: '';
          display: table;
          clear: both;
          float: none;
        }

        &:hover{
          color: $dark_grey;
        }
      }
    }

    .logo{
      align-self: flex-start;
      margin-bottom: 40px;
      margin-bottom: 30px;
      height: 26px;
      flex-shrink: 0;
      width: auto;
    }
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
  &.error .cover{
    background-color: $red;
  }
}

.page--authentication,
.page--onboarding{
  .cover{
    background-image: url('https://www.hans-hausmeister.de/wp-content/uploads/2019/07/hans4-1920x1282-1-1920x1282.png');
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $primary_color;
    background-blend-mode: soft-light;
    transition: background .4s $default_transition_ease;
  }
}
